import React from "react";

import * as styles from "../styles/index.module.css"
import Link from 'gatsby-link'
import Layout from "../components/layout"
import Home from "./home";
import { styled } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import Route
// import { BrowserRouter as Route } from "react-dom";
import { items } from "../interfaces/home-data";

// import { Helmet } from "react-helmet";
// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));



const Productos = () =>
(
    <Layout>
        {/* <Helmet>
            <script src="./assets/vendor/preline/dist/hs-ui.bundle.js" />
        </Helmet> */}
        <Home></Home>
    </Layout>
)

export default Productos

